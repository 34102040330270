<template>
    <div>
      <div class="vx-row mt-10 justify-center">
          <img
            class="w-full md:w-full lg:w-1/2 h-auto"
            src="@/assets/images/QLoader.gif"
            alt="loading..."
            width="200"
          />
      </div>
      <div class="vx-row mt-5 justify-center">
        <h1>{{$t('onBoarding.thankyouHeader')}}</h1>
      </div>
      <div class="vx-row mt-5 justify-center">
        <p>{{$t('onBoarding.thankyouLine1')}}</p>
      </div>
    </div>
</template>

<script>
export default {
  created () {
    this.$store.dispatch('loader/loaderOff')
    setTimeout( () => this.$router.push({ path: '/'}), 3000);
  },
}
</script>
